<script setup>
import { ref, onMounted, watch } from 'vue';
import { userSession } from "o365-modules";
import { getDataObjectById, getOrCreateDataObject} from 'o365-dataobject';
import { confirm } from 'o365-vue-services';
import { procRemoveSignature, procSignSJA, reloadKey } from 'sja.vue.libraries.modules.utilities.js'
import { OPersonsLookup } from "o365-system-lookups";
import SignatureModal from 'sja.vue.libraries.components.SignatureModal.vue';

const props = defineProps({
    sjaID: Number,
    itemID: Number,
    stepID: Number,
    readOnly: Boolean,
    responsibleID: Number
});

const dsParticipants = getOrCreateDataObject({
        id: `dsParticipants_${props.sjaID}`,
        uniqueTable: 'atbv_SJA_SJAParticipants',
        selectFirstRowOnLoad: false,
        maxRecords: -1,
        viewName: 'aviw_SJA_SJAParticipants',
        allowUpdate: true,
        allowInsert: true,
        allowDelete: true,
        disableLayouts: true,
        fields: [
                { name: 'PrimKey', },
                { name: 'ID', },
                { name: 'SJA_ID', },
                { name: 'Signed', },
                { name: 'SignedBy_ID', },
                { name: 'SignedByName', },
                { name: 'Position', },
                { name: 'Person_ID', },
                { name: 'FirstName', },
                { name: 'LastName', },
                { name: 'Name', },
                { name: 'MobileNo', },
                { name: 'Email', },
                { name: 'FileName', },
                { name: 'FileSize', },
                { name: 'FileUpdated', },
                { name: 'FileRef', },
                { name: 'FileSrc', },
                { name: 'Extension', },
                { name: 'Represents', }
        ],
        whereClause: "SJA_ID = " + props.sjaID
});

dsParticipants.on("BeforeSave", async function (options, row) {
    if(row.SJA_ID == null) {
        options.values.SJA_ID = props.sjaID
    }
});

let dsSJA = getDataObjectById(`dsSJA${ props.itemID ? '_' + props.itemID + (props.stepID ? '_' + props.stepID : '') : ''}`)

let currentParticipant = ref(null);

watch(reloadKey, async (newKey, oldQuestion) => {
  dsParticipants.load();
  })

async function signSJA(row) {
    await procSignSJA.execute({ ParticipantKey: row.PrimKey });
    const dataObject = getDataObjectById(row.dataObjectId)
    await dataObject.load()
}

async function deleteSign(row) {
    confirm({
        title: `${$t("Delete")} ${$t("Signature")}`,
        message: `${$t('Are you sure you wish to remove the signature for')} ${row.Name}?`,
        btnTextOk: $t("Yes"),
        btnTextCancel: $t("No")
    }).then(async () => {
        await procRemoveSignature.execute({ ParticipantKey: row.PrimKey });
        dsParticipants.load();
    }).catch(() => {

    });
}

function setPerson(row) {
    currentParticipant.value = row;
}

onMounted(() => {
    dsSJA.load();
    dsParticipants.load();
})

</script>

<template>
    <ODataGrid :key="reloadKey" hideGridMenu :hideActionColumn="props.readOnly" :hideNewRecords="props.readOnly" rowHeight="50" :data-object="dsParticipants">
        <OColumn sortable width="70px" field="Person_ID" :editable="row => row.isNewRecord" :headerName="$t('ID')"
            v-slot:editor="{ row: person }">
            <OPersonsLookup v-if="person.isNewRecord"
                :bind="sel => {person.SJA_ID = props.sjaID, person.Person_ID = sel.ID, person.FirstName = sel.FirstName, person.LastName = sel.LastName, person.Represents = sel.Represents, person.MobileNo = sel.MobileNo, person.Email = sel.Email }"
                v-model="person.Person_ID">
                <template #target="{ target }">
                    <div class="d-flex w-100 h-100 justify-content-center align-items-center">
                        <i :ref="target" class="bi bi-search" type="button" :title="$t('Select from lookup or manually input participant details.')"></i>
                    </div>
                </template>
            </OPersonsLookup>
        </OColumn>
        <OColumn sortable width="150px" field="FirstName" :headerName="$t('First Name')"
            :editable="row => !row.Person_ID && props.readOnly == false" required>
        </OColumn>
        <OColumn sortable width="100px" field="LastName" :headerName="$t('Last Name')" :editable="row => !row.Person_ID && props.readOnly == false" required>
        </OColumn>
        <OColumn sortable width="200px" field="Represents" :headerName="$t('Represents')" :editable="row => !row.Person_ID && props.readOnly == false">
        </OColumn>
        <OColumn sortable width="200px" field="Position" :headerName="$t('Position')" :editable="row => !row.Person_ID && props.readOnly == false">
        </OColumn>
        <OColumn sortable width="100px" field="MobileNo" :headerName="$t('Mobile No')" :editable="row => !row.Person_ID && props.readOnly == false">
        </OColumn>
        <OColumn sortable width="200px" field="Email" :headerName="$t('Email')" :editable="row => !row.Person_ID && props.readOnly == false">
        </OColumn>
        <OColumn width="500px" class="d-flex align-items-center" field="Signed" v-slot="{ row: person }" :headerName="$t('Sign')">
            <i :title="$t('This person has signed')" v-if="person.Signed || person.FileName"
                class="fs-5 text-success px-1 bi bi-check2"></i>
            <span data-format="dd.MM.yyyy HH:mm"> {{ $formatDate(person.Signed, 'General Date Short Time') }}</span>
            <div v-if="(userSession.personId === props.responsibleID || person.Person_ID === userSession.personId) && !person.Signed && (!props.stepID || props.itemID)"
                class="d-flex m-auto">
                <button class="mx-1 btn btn-primary table-button btn-sm" :title="$t('Sign the SJA')" @click="signSJA(person)"
                    v-if="(userSession.personId === props.responsibleID || person.Person_ID === userSession.personId) && !person.Signed && !person.isNewRecord">
                    {{ $t('Sign') }} </button>
                <button class="mx-1 btn btn-primary table-button btn-sm" :title="$t('Sign the SJA with a signature')" v-target:signatureModal @click="setPerson(person)"
                    v-if="(userSession.personId === props.responsibleID || person.Person_ID === userSession.personId) && !person.Signed && !person.isNewRecord">
                    {{ $t('Signature') }} </button>
            </div>
            <span v-if="person.Signed"> &nbsp; {{$t('by')}} &nbsp; </span>
            <span v-if="person.Signed"> {{ person.SignedByName }} &nbsp; </span>
            <span v-if="person.FileRef">
                <img height="40" :src='person.FileSrc'>
            </span>
            <i @click="deleteSign(person)" type="button" :title="$t('Remove signature')"
                v-if="(userSession.personId === props.responsibleID || person.Person_ID == userSession.personId) && person.Signed && props.readOnly == false"
                class="fs-5 px-1 text-black bi bi-x-lg"></i>
        </OColumn>
    </ODataGrid>
    <SignatureModal :sjaID="props.sjaID" :personRow="currentParticipant"></SignatureModal>
</template>

<style scoped>
.table-button {
    background-color: var(--bs-btn-bg) !important;
    border-radius: var(--bs-btn-border-radius);
}

.bi-check2 {
    -webkit-text-stroke: 2px;
}

.bi {
    vertical-align: middle;
}

.table-button:hover {
    background-color: var(--bs-btn-hover-bg) !important;
}
</style>